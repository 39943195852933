import React, { FC } from "react"
// import { Link } from "gatsby"
import Head from "../../components/head"
import Header from "../../components/header"
import Footer from "../../components/footer"
import styles from "../../styles/global.css"
import contact from "../../styles/media_page.css"
import media from "../../styles/media.css"

export default () => (
  <>
    <Header titleText="Media - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
        <div className="clear_both"></div>
        <div className="loginheader_div">
          <div className="loginheader">
            <div className="loginheaderlist_div">
              <img
                alt="icon doller"
                src="/sites/default/files/icon_doller.png"
              />
              <a rel="" href="/pricing/">
                Pricing
              </a>
            </div>
            <div className="loginheaderlist_div">
              <img alt="icon trial" src="/sites/default/files/icon_trial.png" />
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                target="_blank"
              >
                Free Trial
              </a>
            </div>

            <div className="loginheaderlist_div">
              <img alt="user" src="/sites/default/files/user.png" />
              <span className="login_span">
                <a rel="" href="javascript:;">
                  Log In
                </a>
                <ul className="login_ul_son">
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://user.happymangocredit.com"
                      target="_blank"
                    >
                      Individual/Business
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://institution.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Institutions
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://advisor.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Coaches
                    </a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Head />

      {/* <div className="banner_contact">
        <img
          className="contact_img"
          src="/images/fresh-leaves.jpg"
          alt="banner"
        />
        <h1 className="bannerinfo">Mango Slice Blog</h1>
      </div> */}

      <div className="media">
        <div id="preface" className="block">
          <div className="region region-preface">
            <div
              id="block-views-security-block"
              className="block block-views blue we-believe"
            >
              <div className="inner">
                <div className="content">
                  <div className="view view-security view-id-security view-display-id-block view-dom-id-09b08ef9d2ef556de55f0586d9ed9143">
                    <div className="view-content">
                      <div className="item">
                        <div>
                          <div className="title">
                            <div
                              style={{
                                "margin-top": "-10px",
                                "text-align": "left",
                                "font-size": "28px",
                                "font-weight": "bold",
                              }}
                            >
                              <h3>
                              How does Happy Mango help borrowers pay off their loans?
                              </h3>
                            </div>
                            <div>
                              <div>
                                <div
                                  className="excerpt"
                                  style={{
                                    display: "block",
                                    "text-align": "right",
                                    "font-size": "14px",
                                  }}
                                >
                                  <img
                                    src="/sites/all/themes/hmm/images/blogs/date-gray.png"
                                    style={{ width: "16px" }}
                                  />
                                  Aug 31, 2022
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="item blog_desc_section">
                        <img
                          src="/sites/all/themes/hmm/images/blogs/payoffloans.jpg"
                          style={{
                            width: "100%",
                            "margin-top": "20px",
                            "margin-bottom": "20px",
                          }}
                        />
                        <div className="sharethis-inline-share-buttons"></div>
                        <div>
                          <p>
                          From offering customer service phone lines to websites and apps, businesses have done everything they can to make it easy for customers to pay them.
                          </p>
                          <p>
                          The banking system, however, doesn’t always seem to work that way. For instance, the Automated Clearing House (ACH), the main system used for electronic funds transfers, hasn’t changed much since it first went into effect in 1972.
                          </p>
                          <p>
                          And when it’s hard for consumers to make payments, that’s bad for the lender.  Happy Mango has developed the tools that help the borrowers pay off their loans and the lenders minimize defaults. How, exactly does Happy Mango do that? Generally, in six ways.
                          </p>
                        </div>

                        <h3>Give borrowers control over how much to pay.</h3>
                          <p>
                        When customers apply for loans with a bank or credit union on Happy, they are allowed to set the terms of the loan (within certain parameters set by the lender, of course). The customer gets to decide not only how much to borrow but also how long it will take to pay it back based on her own available cash flows.  Happy Mango offers a free “What if” analysis to help customers determine the payment amount within their budget and then calculate the loan amount they can afford to borrow.
                          </p>
                          <h3>
                          Let borrowers set the repayment cycle.
                          </h3>
                          <p>
Most lenders ask customers to pay once a month on a date set by the lender. But one size never fits all. For many borrowers, it is better to pay a smaller amount every week rather than a large amount once a month. That’s why Happy Mango allows customers to select a repayment cycle that matches their earnings cycle.  For example, somebody who is paid weekly on Fridays can select the loan payment frequency that is "weekly" and choose "Friday" as the payment due date.
                          </p>
                          <h3>
Take advantage of Happy Mango’s Smart ACH Payments.
                          </h3>
                          <p>
As noted, the ACH has been around for 50 years – with few changes. Happy Mango offers a “Smart ACH,” which will check the borrower’s bank account balance before initiating the debit, allowing the borrower a chance to make things right and avoid paying the exorbitant overdraft charges.
                          </p>
                          <h3>
Alert borrowers before payments are due.
                          </h3>
                          <p>
Borrowers get an email and SMS text message at least five days before their payment due date, increasing the odds that they’ll prepare accordingly and make timely payments.
                          </p>
                          <h3>
Allow borrowers to make payments anytime.
                          </h3>
                          <p>
Borrowers can log into their Happy Mango accounts to make unscheduled ACH payment anytime.
                          </p>
Make it easy for lenders to help their borrowers.
                          <p>
Happy Mango makes it easy for lenders to modify loan terms and payment schedule based on their customers’ need. A borrower can reach out to the lender on Happy Mango to request  postponing a payment, eliminating a late fee or extending payment term.  Once the lender accepts the request, he can use one of the “Quick Action” buttons on Happy Mango to update the loan payment schedule.  
                          </p>
                          <p>
The more support borrowers get, the more likely they’re likely to remain loyal to their financial institution. The more flexibility they get, the more likely they will pay back their loans.  Customers will remember the people and organizations who help them – and ditch those that do not understand their financial struggles. That’s where Happy Mango comes in. It doesn’t just pay to lend money. It pays to lend a helping hand.
                          </p>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  </>
)
